<template>
  <div class="waylay">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          门卫扫描管理
        </div>
      </div>

      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="快递单号" prop="backNo">
          <el-input v-model="form.mailNo" clearable size="small"></el-input>
        </el-form-item>

        <el-form-item label="签收录入时间：">
          <el-date-picker
            v-model="form.dateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>
        <el-form-item >
          <el-button @click="showDialogDown()" size="mini" type="success">下载扫描信息</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <el-table-column prop="phone" label="扫描员手机号"></el-table-column>
          <el-table-column prop="createdBy" label="扫描员"></el-table-column>
          <el-table-column label="签收录入时间">
            <template slot-scope="scope">
              <span>{{ scope.row.createdTime | dateFormate }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>





      <!--  下载弹窗-->
      <div v-if="dialogVisibleDown">
        <el-dialog
          title="下载扫描信息"
          :visible.sync="dialogVisibleDown"
          width="60%"
          :before-close="handleCancelDown">
          <Down  @cancel="handleCancelDown" @success="handleSuccessDown"></Down>
        </el-dialog>
      </div>
    </el-card>

  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {findSigningInfoByPage} from "@/services/backsign";
import {getUserInfo} from '@/services/user'
import Down from "./components/Down";
export default {
  name: 'scanningList',
  components: { Down },
  data() {
    return {
      userInfo: {},
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      mailNo: null,
      dialogVisibleDown: false,
    }
  },
  methods: {
    // 监听子组件的下载状态，成功时触发
    handleSuccessDown () {
      // 隐藏对话框
      this.dialogVisibleDown = false
      // 刷新列表
      this.loadAllSigningInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelDown () {
      // 隐藏对话框
      this.dialogVisibleDown = false
    },
    // 显示对话框
    showDialogDown() {
      this.dialogVisibleDown = true
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },

    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllSigningInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllSigningInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllSigningInfoByPage()
    },


    async loadAllSigningInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      if (this.form && this.form.dateQuery) {
        let dateBegin = this.form.dateQuery[0]
        let dateEnd = this.form.dateQuery[1]
        this.form.sendDateBegin = dateBegin
        this.form.sendDateEnd = dateEnd
      }else {
        this.form.sendDateBegin = ""
        this.form.sendDateEnd = ""
      }
      const {data} = await findSigningInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },



  },
  created() {
    this.loadAllSigningInfoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },


  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
