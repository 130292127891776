import request from '@/utils/request'


// 签收包裹扫描
export function signing(data) {
  return request({
    method: 'post',
    url: `/auth/back/signing`,
    data
  })
}


// 签收分页多条件退货包裹扫描查询
export function findSigningInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/back/findSigningInfoByPage`,
    data
  })
}
